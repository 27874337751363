$xs: 320px;
$sm: 700px;
$md: 1024px;
$lg: 1800px;

$font-helvetica: 'helvetica', sans-serif;


@mixin max-responsive($class) {
  @if $class == xs {
    @media (max-width: $xs) {
      @content;
    }
  } @else if $class == sm {
    @media (max-width: $sm) {
      @content;
    }
  } @else if $class == md {
    @media (max-width: $md) {
      @content;
    }
  } @else if $class == lg {
    @media (max-width: $lg) {
      @content;
    }
  } @else {
    @warn "responsive mixin supports: xs, sm, md, lg";
  }
}

@mixin responsive($class) {
  @if $class == xs {
    @media (min-width: $xs) {
      @content;
    }
  } @else if $class == sm {
    @media (min-width: $sm) {
      @content;
    }
  } @else if $class == md {
    @media (min-width: $md) {
      @content;
    }
  } @else if $class == lg {
    @media (min-width: $lg) {
      @content;
    }
  } @else {
    @warn "responsive mixin supports: xs, sm, md, lg";
  }
}


/*
* Ease
*/
$ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-in-sine: cubic-bezier(0.47, 0, 0.745, 0.715);
$ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$ease-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
$ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
$ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
$ease-in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
$ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
$ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);
$ease-in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);


/*
* Hover
*/

@mixin hover() {
  @media (hover: hover) {
    &:hover{
      @content;
    }
  }
  .ie11 & {
    &:hover{
      @content;
    }
  }
}

.objetsscene {
  position: absolute;
  border: none;
  background: none;
  color: rgba(255, 255, 255, 0);
  z-index: 10;
  border-radius: 60px;
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}

 .objetsscene:focus {
  background: var(--brand-color); /* Assurez-vous que --brand-color est défini */
  border: 2px solid rgb(0, 0, 0);
  padding: 10px;
  color: rgb(52, 48, 48);
  outline: none;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  pointer-events: none; // pour s'assurer qu'il n'y a pas d'interaction
}
