@import "../styles/shared.scss";


.landing {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.ParallaxTextLanding {
  width: 98%;
  border-bottom: 1px solid white;
  padding-top: 10px;
  padding-bottom: 10px;
}

.header {
    width: 100%;   
}

.text {
    white-space: nowrap;
    font-size: 2rem;
}

.BlocBas {
    bottom: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    background-color: black;
    align-items: flex-end;
    justify-content: flex-end;
    @include max-responsive(sm){
        flex-direction: column-reverse;
        gap: 10px;
        align-items: start;
     }
}



.imgBloc {
    position: absolute;
    width: 100%;
    height: auto; /* Adaptez la hauteur si nécessaire */
    bottom: 0;
    right:0;
    @include max-responsive(sm){
        width: 100%;

    
    }
}

.imgBloc img {
    width: 100%; /* Cela fait référence à 100% de .imgBloc, qui est 30% de .BlocBas */
    height: auto; /* Maintient l'aspect ratio de l'image */
   
}


.citeBloc {
    color: azure;
    border-left: 1px solid white;
    margin-left: 15px;
    margin-bottom: 25px;
    width: 60%;
    height: 70%;
    padding-left: 10px;
    @include max-responsive(sm){
        margin-left: 15px;
    }
}

.citation {
 font-size: 40px;
 text-align: left;
}

.contactBloc {
display: flex;
flex-direction: column;
justify-content: flex-end;
height: 70%;

@include max-responsive(sm){
    height: 60%;

}
}

.contact {
    font-size: 18px;
    text-align: left;  
   }


   .contactMail {
    margin-top: 10px;
    font-size: 20px;
    text-align: left;
    color: white;
    display: flex;
    align-items: center; /* Centre verticalement les éléments enfants */
    justify-content: flex-start; /* Alignement horizontal au début */
    gap: 10px; /* Espace entre le SVG et le texte */
   

    a {
        color: white;

    }
   }

   