@import "../styles/shared.scss";

.private {
    background-color: black;
    position: absolute;
    width: 100svw;
    padding-bottom: 100px;
}

.videoWrapper {
    margin-top: 150px;

    h1{
        color: white;
        margin-bottom: 50px;
    }
}


.videoplayer {
    width: 50%;

    @include max-responsive(sm){
        width: 90%;
    
    }
}
