@import "../styles/shared.scss";




.headerTitle {
    font-size: clamp(16px,14.3vw,460px);
    line-height: 0px;

}

.william {
    color: white; /* Couleur pour WILLIAM */
  }
  
  .klein {
    color: #b50000; /* Couleur pour KLEIN */
  }