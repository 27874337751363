@import "../styles/shared.scss";


  

.parallax {
    overflow: hidden;
    letter-spacing: -2px;
    line-height: 1;
    margin: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    
    
  }
  
  .parallax .scroller {
    
    display: flex;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    text-transform: uppercase;
    font-size: 35px;
    color: #faebd7;
  }
  
  .parallax span {
    display: block;
    margin-right: 30px;
    
  }
  